@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

body {
    font-family: 'Ubuntu', sans-serif;
}

li {
    list-style-type: none; /* Apply globally */
}

.ais-SearchBox-input {
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 80%;
    max-width: 800px;
    height: 50px;
}

/* If the search icon is an SVG or a separate element */
.ais-SearchBox .ais-SearchBox-submit,
.ais-SearchBox .ais-SearchBox-reset {
    display: none;
}

.ais-Pagination-list {
    display: flex;
    justify-content: center;
}

.ais-Pagination-link {
    text-decoration: none;
    color: #000; /* Sets the text color for pagination links */
    border: 1px solid #ddd; /* Adds a border around the pagination links */
    padding: 5px 10px; /* Adds some padding inside the borders */
    border-radius: 4px; /* Optional: rounds the corners of the borders */
    display: block; /* Make sure the padding and border are applied correctly */
}

.ais-Pagination-item {
    margin-right: 5px; /* Adds space to the right of each pagination item */
}

.ais-Pagination-item:last-child {
    margin-right: 0; /* Ensures the last item does not have extra margin on the right */
}

/* Style for the active page number */
.ais-Pagination-item--selected .ais-Pagination-link {
    border-color: rgba(25, 25, 150, 0.5); /* Sets a distinct border color for the active page */
    background-color: #f5f5f5; /* Optional: sets a background color for the active page */
}

/* Refinement list container styling */
.ais-RefinementList {
    padding: 15px;
    margin-bottom: 20px; /* Space below the refinement list */
}

/* Individual item styling */
.ais-RefinementList-item {
    padding: 5px 10px;
    margin-bottom: 10px; /* Space between refinement items */
    display: block;
}

/* Custom checkbox styling */
.ais-RefinementList-checkbox {
    accent-color: #007bff; /* This is a simple way to change the color of the checkbox */
    margin-right: 10px;
}

/* Label styling */
.ais-RefinementList-labelText {
    flex-grow: 1; /* Ensures the text takes up the available space */
}

/* Hover effect */
.ais-RefinementList-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

/* Active state styling */
.ais-RefinementList-item.ais-RefinementList-item--selected {
    background-color: #e9ecef;
    border-color: #007bff;
}

.ais-RefinementList-count {
    display: none; /* This hides the facet count */
}